export default [
  {
    title: 'ダッシュボード',
    icon: { icon: 'tabler-smart-home' },
    to: 'clients-dashboard',
    action: 'manage',
    subject: 'client',
    badgeClass: 'bg-light-primary text-primary',
  },
  {
    title: 'イチオシ',
    icon: { icon: 'material-symbols-campaign' },
    to: 'clients-pickup',
    action: 'manage',
    subject: 'client',
  },
  {
    title: '施設を探す',
    icon: { icon: 'tabler-list-search' },
    to: 'clients-search',
    action: 'manage',
    subject: 'client',
  },
  {
    title: 'お気に入り',
    icon: { icon: 'material-symbols-bookmark-add' },
    to: 'clients-bookmark',
    action: 'manage',
    subject: 'client',
  },
  {
    title: '進捗一覧',
    icon: { icon: 'material-symbols-order-approve-outline' },
    to: 'clients-entry',
    action: 'manage',
    subject: 'client',
  },
  {
    title: 'お問い合わせ',
    icon: { icon: 'tabler-message' },
    to: 'clients-chat',
    action: 'manage',
    subject: 'client',
  },
  {
    title: '施設の提案希望',
    icon: { icon: 'tabler-brand-wechat' },
    to: 'clients-request',
    action: 'manage',
    subject: 'client',
  },
  {
    title: 'お知らせ',
    icon: { icon: 'tabler-news' },
    to: 'clients-news',
    action: 'manage',
    subject: 'client',
  },
]
