export default [
  {
    title: 'ダッシュボード',
    icon: { icon: 'tabler-smart-home' },
    to: 'owners-dashboard',
    action: 'manage',
    subject: 'owner',
    badgeClass: 'bg-light-primary text-primary',
  },
  {
    title: 'イチオシ',
    icon: { icon: 'material-symbols-campaign' },
    to: 'owners-pickup',
    action: 'manage',
    subject: 'owner',
  },
  {
    title: '申請情報一覧',
    icon: { icon: 'material-symbols-order-approve-outline' },
    to: 'owners-entry',
    action: 'manage',
    subject: 'owner',
  },
  {
    title: '自社使用一覧',
    icon: { icon: 'tabler-list-check' },
    to: 'owners-scheduler',
    action: 'manage',
    subject: 'owner',
  },
  {
    title: '売上確認',
    icon: { icon: 'tabler-report-money' },
    to: 'owners-sales',
    action: 'manage',
    subject: 'owner',
  },
  {
    title: '施設情報',
    icon: { icon: 'tabler-building-store' },
    to: 'owners-facility',
    action: 'manage',
    subject: 'owner',
  },
  {
    title: 'お問い合わせ',
    icon: { icon: 'material-symbols-chat-bubble-outline' },
    to: 'owners-chat',
    action: 'manage',
    subject: 'owner',
  },
  {
    title: 'お知らせ',
    icon: { icon: 'material-symbols-newspaper' },
    to: 'owners-news',
    action: 'manage',
    subject: 'owner',
  },
]
